import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Card from "../../components/Card";
import CenterContainer from "../../components/CenterContainer";
import { setPageTitle, getPathName, getURL } from "../../assets/scripts/Main";
import WaitingLoad from "../../components/WaitingLoad";

export default class Center extends Component {
  state = {
    slideImageUrl: "",
    images: [],
  };

  componentDidMount = () => {
    this.setState({
      slideImageUrl: "",
    });

    axios
      .get(getURL("API") + "/photosByAircraft/" + getPathName(2))
      .then((response) => response.data)
      .then((data) => {
        this.setState({ images: data });
      });
  };

  handleClickImg = (imgUrl) => {
    this.setState({
      slideImageUrl: imgUrl,
    });
  };

  handleCLoseSlide = () => {
    this.setState({
      slideImageUrl: "",
      slideWasClosed: true,
    });
  };

  render() {
    let aircraft = this.props.items;
    let updated_at = aircraft.updated_at;
    let title =
      (aircraft.manufacturer ? aircraft.manufacturer + " " : "") +
      (aircraft.model ? aircraft.model + " " : "") +
      (aircraft.manufacturer
        ? "(" + aircraft.registration_code + ")"
        : aircraft.registration_code);

    setPageTitle(title + " | Aeronaves.Info");

    return (
      <>
        <CenterContainer componentclass="noRight">
          <Card componentClass="article aircraft">
            <span className="publicationDate">
              {updated_at ? (
                <>
                  {"Última atualização: " +
                    updated_at.substring(0, 10).split("-").reverse().join("/")}
                </>
              ) : (
                <WaitingLoad width={"100px"} height={"15px"} />
              )}
            </span>

            <h1>
              {title && title !== "undefined" ? (
                <>{title}</>
              ) : (
                <>
                  <WaitingLoad width={"100%"} height={"130px"} />

                  <WaitingLoad width={"100%"} height={"15px"} />
                  <WaitingLoad width={"100%"} height={"15px"} />
                  <WaitingLoad width={"100%"} height={"15px"} />
                  <WaitingLoad width={"50%"} height={"130px"} />
                  <WaitingLoad width={"100%"} height={"15px"} />
                  <WaitingLoad width={"100%"} height={"15px"} />
                  <WaitingLoad width={"100%"} height={"15px"} />
                </>
              )}
            </h1>

            {/* SLIDE  */}
            <div
              id="imgZoomComponent"
              style={{ display: this.state.slideImageUrl ? "unset" : "none" }}
            >
              <div className="thumbsContainer">
                <div
                  onClick={() => this.handleCLoseSlide()}
                  className="closeBtnMobile"
                >
                  {"✕ fechar"}
                </div>
                <div className="thumbs">
                  {this.state.images.map((image) => (
                    <img
                      key={image.id}
                      id={"img_" + image.id}
                      src={image.url}
                      style={{
                        borderColor:
                          image.url === this.state.slideImageUrl
                            ? "#fff"
                            : "transparent",
                      }}
                      onClick={() => this.handleClickImg(image.url)}
                    />
                  ))}
                </div>
                <div
                  onClick={() => this.handleCLoseSlide()}
                  className="closeBtn"
                >
                  {"✕ fechar"}
                </div>
              </div>
              <img class="image" src={this.state.slideImageUrl} />
            </div>
            {/* SLIDE  */}
            <div className="photos">
              {this.state.images.map((image) => (
                <img
                  key={image.id}
                  id={"img_" + image.id}
                  src={image.url}
                  onClick={() => this.handleClickImg(image.url)}
                />
              ))}
            </div>
          </Card>
        </CenterContainer>
      </>
    );
  }
}
