import React from "react";

var style = document.createElement("style");
style.type = "text/css";
var keyFrames =
  ".waitingLoad{\
    animation-duration: 1.5s;\
    animation-fill-mode: forwards;\
    animation-iteration-count: infinite;\
    animation-name: placeHolderShimmer;\
    animation-timing-function: linear;\
    background: #eeeeee;\
    background: linear-gradient(to right, #dde1e8 8%, #ebeef2 38%, #dde1e8 54%);\
    background-size: 1000px 640px;\
    display: inline-block;\
    border-radius: 2px;\
  }\
  @keyframes placeHolderShimmer {\
    0% {\
      background-position: -468px 0;\
    }\
    100% {\
      background-position: 468px 0;\
    }\
  }\
  ";
style.innerHTML = keyFrames.replace(/A_DYNAMIC_VALUE/g, "180deg");
document.getElementsByTagName("head")[0].appendChild(style);

export default (props) => (
  <div
    className={"waitingLoad " + props.className}
    style={{
      height: props.height,
      width: props.width,
    }}
  ></div>
);
