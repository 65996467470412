import React, { Component } from "react";
export default class Content extends Component {
  render() {
    return (
      <>
        <form method="post" className="defaultContainer error404">
          <div id="background">
            <div className="align">
              <div className="bgAlign">
                <div className="errorText">página não encontrada</div>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}
