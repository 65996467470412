import React, { Component } from "react";
import DefaultContainer from "../../components/DefaultContainer";
import axios from "axios";
import Left from "./Left";
import Center from "./Center";
import { Redirect } from "react-router-dom";
import { getURL, getPathName, scrollToTop } from "../../assets/scripts/Main";

export default class Index extends Component {
  state = {
    items: [],
  };

  componentDidMount() {
    scrollToTop();
    axios
      .get(getURL("API") + "/aircraft/" + getPathName(2))
      .then((response) => response.data)
      .then((data) => {
        this.setState({ items: data });
      });
  }

  render() {
    var url = "";

    this.state.items.length > 0 &&
      this.state.items.map((item) => {
        url = item.registration_code;
      });

    return (
      <>
        {url && url !== getPathName(3) && (
          <Redirect to={{ pathname: "/pagina-nao-encontrada" }} />
        )}

        <DefaultContainer>
          <Left items={this.state.items} />
          <Center items={this.state.items} />
        </DefaultContainer>
      </>
    );
  }
}
