import React, { Component } from "react";
import DefaultContainer from "../../components/DefaultContainer";
import Content from "./Content";
import { setPageTitle, getPathName } from "../../assets/scripts/Main";

export default class Index extends Component {
  render() {
    setPageTitle("Cadastro");
    return (
      <>
        {getPathName(1) !== "cadastro" ? (
          <>{window.location.assign("/cadastro")}</>
        ) : (
          <DefaultContainer>
            <Content />
          </DefaultContainer>
        )}
      </>
    );
  }
}
