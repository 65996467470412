import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Card from "../../components/Card";
import CenterContainer from "../../components/CenterContainer";
import { setPageTitle, getPathName, getURL } from "../../assets/scripts/Main";
import WaitingLoad from "../../components/WaitingLoad";

var lastListedId = null;
var lastCalledId = null;
var lastPostPosition = null;
export default class Center extends Component {
  state = {
    slideImageUrl: "",
    photos: [],
  };

  componentDidMount = () => {
    this.setState({
      slideImageUrl: "",
    });
    this.listUserPhotos(0);
  };

  listUserPhotos = (lastListedId) => {
    axios
      .get(
        getURL("API") +
          "/photosByUsername/" +
          getPathName(2) +
          "/" +
          lastListedId
      )
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          photos: this.state.photos.concat(data),
        });
      });
  };

  paginationScroll = (arg) => {
    var post = document.getElementById(`img_${arg}`);
    if (post) {
      lastPostPosition = post.getBoundingClientRect().top;
    }
    if (
      arg &&
      lastPostPosition < window.innerHeight + 200 &&
      lastCalledId !== arg
    ) {
      this.listUserPhotos(arg);
      lastCalledId = arg || lastCalledId;
      lastListedId = null;
      lastPostPosition = null;
    }
  };

  handleClickImg = (imgUrl) => {
    this.setState({
      slideImageUrl: imgUrl,
    });
  };

  handleCLoseSlide = () => {
    this.setState({
      slideImageUrl: "",
      slideWasClosed: true,
    });
  };

  render() {
    var user = this.props.items;

    setPageTitle(user.name + " | Aeronaves.Info");
    window.onscroll = () => {
      this.paginationScroll(lastListedId);
    };

    return (
      <>
        <CenterContainer componentclass="noRight">
          <Card componentClass="article userPage">
            <h1>
              {"name" ? (
                <>Fotos de {user.name}:</>
              ) : (
                <>
                  <WaitingLoad width={"100%"} height={"15px"} />
                  <br />
                  <br />
                  <WaitingLoad width={"50%"} height={"130px"} />
                  <WaitingLoad width={"50%"} height={"130px"} />
                  <br />
                  <br />
                  <WaitingLoad width={"50%"} height={"130px"} />
                  <WaitingLoad width={"50%"} height={"130px"} />
                </>
              )}
            </h1>
            {/* SLIDE  */}
            <div
              id="imgZoomComponent"
              className="userPage"
              style={{ display: this.state.slideImageUrl ? "unset" : "none" }}
            >
              <div>
                <div
                  onClick={() => this.handleCLoseSlide()}
                  className="closeBtn"
                >
                  {"✕ fechar"}
                </div>
              </div>

              <img class="image" src={this.state.slideImageUrl} />
            </div>
            {/* SLIDE  */}
            <div className="photos">
              {this.state.photos &&
                this.state.photos.map((image) => {
                  lastListedId = image.id;
                  return (
                    <img
                      key={image.id}
                      id={"img_" + image.id}
                      src={image.photo_url}
                      onClick={() => this.handleClickImg(image.photo_url)}
                    />
                  );
                })}
            </div>
          </Card>
        </CenterContainer>
      </>
    );
  }
}
