import React, { Component } from "react";
export default class Content extends Component {
  render() {
    return (
      <>
        <div className="aboutPage">
          <div className="row">
            <div className="col content">
              <p>
                O <strong>Aeronaves.Info</strong> surgiu Abril em 2024, tendo
                como propósito inicial, o compartilhamento de fotos e
                informações sobre aeronaves, aeroportos, companhias aéreas e
                fabricantes de aeronaves.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col content">
              <p>
                O principal objetivo, é criar uma rede de compartilhamento e
                interação de forma descentralizada, servindo de plataforma
                interativa e aberta a qualquer usuário interessado em criar
                conteúdos sobre aviação, incluindo publicação de fotos por{" "}
                <strong>spotters</strong>.
              </p>
            </div>
          </div>
          {/*           <div className="row">
            <div className="col title channels">
              <div>Canais</div>
            </div>
            <div className="col content">
              <div className="channelCards discord"></div>
              <div className="channelCards whatsapp"></div>
              <div className="channelCards facebook"></div>
              <div className="channelCards instagram">
                <div className="bg"></div>
              </div>
              <div className="channelCards linkedin"></div>
              <div className="channelCards youtube"></div>
            </div>
          </div> */}
          {/*           <div className="row title">
            <h2>Co-Founders</h2>
          </div>
          <div className="row content photo teamphoto">
            <div className="photo .teamphoto"></div>
          </div> */}
        </div>
      </>
    );
  }
}
