import React from "react";
import { Typography } from "@mui/material";
export default (props) => (
  <>
    <Typography
      {...props}
      sx={{
        color: "#dee5ed",
      }}
    />
  </>
);
