import { getURL } from "../assets/scripts/Main";
import axios from "axios";

const api = axios.create({
  baseURL: getURL("API"),
});

const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("TOKEN"),
};

const get = (url, data) => {
  return api.get(url, {
    headers,
    params: data,
  });
};

const post = (url, data) => {
  return api.post(url, data, {
    headers: headers,
  });
};

const put = (url, data) => {
  return api.put(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + sessionStorage.getItem("TOKEN"),
    },
  });
};

const destroy = (url, data) => {
  return api.delete(url, {
    data: data,
    headers: headers,
  });
};

export { get, post, put, destroy };
