export function regEx(validate, str) {
  var regEx = "";
  switch (validate) {
    case "username":
      //min 3, max 16 caracteres
      //letra minúscula
      //numero
      //-
      //.
      //_
      regEx = /^[a-z0-9._-]{3,16}$/;
      break;
    case "email":
      //validacao padrao de email
      regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      break;
    case "password":
      //min 6 caracteres
      //letra maiúscula ou minúscula
      //numero
      //caractere especial
      regEx = /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-zA-Z]){1}).*$/;
      break;
  }
  return regEx.test(str);
}
