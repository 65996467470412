import React, { Component } from "react";
import RightContainer from "../../components/RightContainer";
import axios from "axios";
import { Link } from "react-router-dom";
import { getURL } from "../../assets/scripts/Main";
import WaitingLoad from "../../components/WaitingLoad";
export default class Right extends Component {
  state = {
    items: [],
  };

  componentDidMount() {
    axios
      .get(getURL("API") + "/keywords")
      .then((response) => response.data)
      .then((data) => {
        this.setState({ items: data });
      });
  }

  render() {
    return (
      <>
        <RightContainer>
          {/*
          TODO: PASSAR PARA INGLES           <div className="content">
            <h4>Exibir na Timeline</h4>
            <div className="filtrosTimeline">Artigos</div>
            <div className="filtrosTimeline inativo">Fórum</div>
          </div> */}
          {this.state.items.length > 0 ? (
            <div className="content">
              <h4>Tags</h4>
              {this.state.items.map((item) => (
                <Link
                  to={`/tag/${item.name.replace(/ /g, "")}`}
                  key={`kword${item.id}`}
                  className="tags"
                >
                  {item.name}
                  <span> ({item.qtt})</span>
                </Link>
              ))}
            </div>
          ) : (
            <>
              <WaitingLoad width="100%" height="40px" />
              <WaitingLoad width="100%" height="260px" />
            </>
          )}
          <div className="about">
            <Link to="/termos-de-uso" target="_Blank">
              {" "}
              Termos de uso
            </Link>{" "}
            -
            <Link to="/sobre" target="_Blank">
              {" "}
              Sobre nós
            </Link>
            <br />
            <br />
            <div
              style={{
                backgroundImage: `url(./assets/img/icons/br-mg.png)`,
              }}
            >
              Aeronaves.Info &copy; 2024&nbsp;&nbsp;|
            </div>
          </div>
        </RightContainer>
      </>
    );
  }
}
