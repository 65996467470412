import React, { Component } from "react";
import DefaultContainer from "../../components/DefaultContainer";
import Left from "../Home/Left";
import Center from "./Center";

export default class Index extends Component {
  render() {
    return (
      <DefaultContainer>
        <Left />
        <Center />
      </DefaultContainer>
    );
  }
}
