import React, { Component } from "react";
import DefaultContainer from "../../components/DefaultContainer";
import axios from "axios";
import Left from "./Left";
import CtrEditProfile from "./CtrEditProfile";
import CtrShowProfile from "./CtrShowProfile";
import CtrSendPhoto from "./CtrSendPhoto";
import {
  setPageTitle,
  getURL,
  getPathName,
  getDataToken,
  scrollToTop,
} from "../../assets/scripts/Main";

export default class Index extends Component {
  state = {
    items: [],
    token: localStorage.getItem("token"),
    username: getPathName(2),
    dataToken: getDataToken(),
  };

  componentDidMount = () => {
    scrollToTop();
    this.getProfile();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (getPathName(2) !== prevState.username) {
      this.setState({ username: getPathName(2) });
      this.getProfile();
    }

    if (prevState.items !== this.state.items) {
      if (this.state.items.length === 0)
        window.location.href = "/pagina-nao-encontrada";
    }
  };

  getProfile = () => {
    if (this.state.username !== undefined)
      axios
        .get(getURL("API") + "/profile/" + this.state.username, {
          headers: { "x-access-token": this.state.token },
        })
        .then((response) => response.data)
        .then((data) => {
          this.setState({ items: data });
        });
  };

  Center = () => {
    switch (getPathName(1)) {
      case "editar-perfil":
        return <CtrEditProfile items={this.state.items} />;
      case "enviar-foto":
        return <CtrSendPhoto items={this.state.items} />;
      default:
        return <CtrShowProfile items={this.state.items} />;
    }
  };
  render() {
    setPageTitle("Perfil");
    return (
      <DefaultContainer>
        <Left items={this.state.items} />
        {this.Center()}
      </DefaultContainer>
    );
  }
}
