import React from "react";
import { TextField } from "@mui/material";
export default (props) => (
  <>
    <TextField
      {...props}
      size="small"
      variant="filled"
      fullWidth
      sx={{
        backgroundColor: "#0c1118",
        "& .MuiFilledInput-input": {
          color: "white",
        },
      }}
    />
  </>
);
