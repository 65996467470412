import React, { Component } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { getURL } from "../../assets/scripts/Main";
import { GoogleLogin } from "react-google-login";
import { Link } from "react-router-dom";

export default class Content extends Component {
  state = {
    items: [],
    email: "",
    password: "",
    signInIntegration: null,
  };

  handleKeyPress = (event) => {
    this.setState({
      email: ReactDOM.findDOMNode(this.refs.email).value,
      password: ReactDOM.findDOMNode(this.refs.password).value,
    });
    if (event.key === "Enter") {
      this.signIn();
    }
    this.errMsg("");
  };

  signIn = () => {
    if (this.state.email === "") {
      this.errMsg("v1");
    } else if (this.state.password === "") {
      this.errMsg("v2");
    } else {
      axios
        .post(getURL("API") + "/signIn", {
          email: this.state.email,
          password: this.state.password,
        })
        .then((response) => response.data)
        .then((data) => {
          this.setState({ items: data });
        })
        .catch((error) => {
          if (error.hasOwnProperty("response")) {
            this.errMsg(error.response.data.msg);
          } else {
            this.errMsg(error);
          }
        });
    }
  };

  signInGoogle = () => {
    axios
      .post(getURL("API") + "/signIn/google", {
        signInIntegration: this.state.signInIntegration,
      })
      .then((response) => response.data)
      .then((data) => {
        this.setState({ items: data });
      })
      .catch((error) => {
        this.errMsg(error.response.data.msg);
      });
  };

  responseGoogle = (response) => {
    this.setState({
      signInIntegration: response.profileObj
        ? {
            integrationId: response.profileObj.googleId,
            email: response.profileObj.email,
            name: response.profileObj.name,
            imageUrl: response.profileObj.imageUrl,
          }
        : null,
      email: response.profileObj ? response.profileObj.email : "",
    });
    this.signInGoogle();
  };

  errMsg = (msg) => {
    var passwordErrMsg = "";
    var integrationErrMsg = "";
    var emailErrMsg = "";
    var googleErrMsg = "";
    if (msg === "v1") {
      emailErrMsg = "Insira seu Email";
    } else if (msg === "v2") {
      passwordErrMsg = "Insira sua Senha";
    } else if (msg === "1") {
      passwordErrMsg = "Usuário ou Senha Inválida";
    } else if (msg === "2") {
      integrationErrMsg = "Houve um erro na autenticação";
    } else if (msg === "3") {
      emailErrMsg =
        "Email bloqueado por excessivas tentativas de login. Clique em <a href='./esqueci-minha-senha'>Esqueci Minha Senha</a> para recuperar o acesso.";
    } else if (msg === "4") {
      googleErrMsg =
        "Usuário não encontrado. <a href='./cadastro'>Criar Acesso</a>";
    }
    ReactDOM.findDOMNode(this.refs.passwordErrMsg).innerHTML = passwordErrMsg;
    ReactDOM.findDOMNode(this.refs.integrationErrMsg).innerHTML =
      integrationErrMsg;
    ReactDOM.findDOMNode(this.refs.emailErrMsg).innerHTML = emailErrMsg;
    ReactDOM.findDOMNode(this.refs.googleErrMsg).innerHTML = googleErrMsg;
  };

  render() {
    let success = this.state.items.success ? this.state.items.success : null;
    let msg = this.state.items.msg ? this.state.items.msg : null;

    if (success) {
      let dataToken = JSON.parse(atob(msg.split(".")[1]));
      let page = localStorage.getItem("page");

      localStorage.setItem("token", msg);

      if (typeof page !== "undefined" && page !== null) {
        localStorage.removeItem("page");
        window.location.href = page;
      } else {
        window.location.href = "/perfil/" + dataToken.username;
      }
    }

    return (
      <>
        <form method="post" className="defaultContainer signIn">
          <div id="background">
            <div className="align">
              <div className="bgAlign"></div>
              <div className="formAlign">
                <div className="formSignIn defaultBox">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email"
                    onKeyUp={this.handleKeyPress}
                    ref="email"
                  />
                  <span
                    className="errMsg"
                    id="emailErrMsg"
                    ref="emailErrMsg"
                  ></span>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Senha"
                    onKeyUp={this.handleKeyPress}
                    ref="password"
                  />
                  <span
                    className="errMsg"
                    id="passwordErrMsg"
                    ref="passwordErrMsg"
                  ></span>
                  <input type="button" value="Entrar" onClick={this.signIn} />
                  <div id="alternativeSignInSeparator">Ou</div>
                  <div id="googleButton">
                    <GoogleLogin
                      clientId="114613198168-lmpbhmus6e4fplaecck81o6umfvjec5o.apps.googleusercontent.com"
                      buttonText="Acessar com Google"
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                  </div>
                  <span
                    className="errMsg"
                    id="googleErrMsg"
                    ref="googleErrMsg"
                  ></span>
                  <span
                    className="errMsg"
                    id="integrationErrMsg"
                    ref="integrationErrMsg"
                  ></span>
                </div>
                <div className="passwordRecovery">
                  <Link to="./esqueci-minha-senha">ESQUECI MINHA SENHA 😥</Link>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}
