import React, { Component } from "react";
import DefaultContainer from "../../components/DefaultContainer";
import Content from "./Content";
import { setPageTitle } from "../../assets/scripts/Main";
setPageTitle("Aeronaves.Info");

export default class Index extends Component {
  render() {
    setPageTitle("Termos de Uso");
    return (
      <DefaultContainer>
        <Content />
      </DefaultContainer>
    );
  }
}
