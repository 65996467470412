import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { getURL, generateThumb } from "../../assets/scripts/Main";
import CenterContainer from "../../components/CenterContainer";

var lastListedId = null;
var lastCalledId = null;
var lastPostPosition = null;
export default class Center extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  componentDidMount = () => {
    this.reqService(0);
  };
  componentWillUnmount() {
    lastListedId = null;
    lastCalledId = null;
    lastPostPosition = null;
    //removendo a função paginationScroll do evento
    window.onscroll = () => {};
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.username !== prevProps.username) {
      this.setState({
        items: [],
      });
      this.reqService(0);
    }
  };

  reqService = (arg) => {
    let link =
      typeof this.props.username !== "undefined"
        ? "user/" + this.props.username
        : "";
    if (link === "") {
      link = arg === 0 ? "" : arg;
    }
    axios
      .get(getURL("API") + "/feed/" + link)
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          items: this.state.items.concat(data),
        });
      });
  };
  paginationScroll = (arg) => {
    var post = document.getElementById(`pb${arg}`);
    if (post) {
      lastPostPosition = post.getBoundingClientRect().top;
    }

    if (
      lastPostPosition < window.innerHeight + 200 &&
      lastCalledId !== arg &&
      arg &&
      window.location.pathname === "/"
    ) {
      this.reqService(arg);
      lastCalledId = arg || lastCalledId;
      lastListedId = null;
      lastPostPosition = null;
    }
  };

  render() {
    window.onscroll = () => {
      this.paginationScroll(lastListedId);
    };
    return (
      <CenterContainer componentclass={"feed noRight"}>
        {/* específico para profile */}
        {this.props.children}
        {/* específico para profile */}

        {this.state.items.map((item) => {
          lastListedId = item.id;

          return (
            <div
              className={`postBox aircraft`}
              id={`pb${item.id}`}
              key={`pb${item.id}`}
            >
              <Link
                to={"/foto/" + item.id + "/" + (item.registration_code || "")}
              >
                <div className="headerBar">
                  <div className={"title"}>
                    {item.manufacturer
                      ? item.registration_code +
                        " " +
                        item.manufacturer +
                        (item.model ? "" + item.model : "")
                      : "✈️"}
                  </div>
                  <div className="description"></div>
                </div>

                <div
                  className="center artigo"
                  style={
                    item.photo_url
                      ? {
                          backgroundImage: `url(${generateThumb(
                            item.photo_url
                          )})`,
                        }
                      : {
                          backgroundImage: `url(./assets/img/default_images/aircraft.png)`,
                        }
                  }
                >
                  {item.desctiption}
                </div>
              </Link>
              <div className="footerBar">
                <div
                  className="profilePicture"
                  style={
                    item.user_profile_picture
                      ? {
                          backgroundImage: `url(${item.user_profile_picture})`,
                        }
                      : {
                          backgroundImage: `url(./assets/img/default_images/user.png)`,
                        }
                  }
                ></div>

                <div className="name">{item.name}</div>

                {/*         <div className="counters">
                  <div className="icon">
                    <Icon icon="cursor-pointer" width="20" color="#b7b7b7" />
                  </div>
                  <div className="value">{item.views ? item.views : 0}</div>
                </div> */}
              </div>
            </div>
          );
        })}
      </CenterContainer>
    );
  }
}
