import React, { Component } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { regEx } from "../../assets/scripts/RegEx";
import { getURL, getPathName } from "../../assets/scripts/Main";

export default class Content extends Component {
  state = {
    email: "",
    password: "",
    confirmPassword: "",
    getCode: null,
    emailSent: null,
  };

  handleEmailKeyPress = (event) => {
    this.setState({
      email: ReactDOM.findDOMNode(this.refs.email).value,
    });
    if (event.key === "Enter") {
      this.PasswordRecoveryGet();
    }
  };

  handlePasswordKeyPress = (event) => {
    this.setState({
      password: ReactDOM.findDOMNode(this.refs.password).value,
      confirmPassword: ReactDOM.findDOMNode(this.refs.confirmPassword).value,
    });
    if (event.key === "Enter") {
      this.PasswordRecoveryValidate();
    }
  };

  PasswordRecoveryGet = () => {
    if (this.state.email === "") {
      this.errMsg("v1");
    } else if (!regEx("email", this.state.email)) {
      this.errMsg("v2");
    } else {
      this.setState({
        emailSent: true,
      });
      axios
        .post(getURL("API") + "/PasswordRecovery/getCode", {
          email: this.state.email,
        })
        .then((response) => response.data)
        .then((data) => {
          this.setState({ items: data });
        })
        .catch((error) => {
          this.errMsg(error.response.data.msg);
        });
    }
  };

  PasswordRecoveryValidate = () => {
    if (this.state.password.length < 6 || this.state.password.length > 150) {
      this.errMsg("v3");
    } else if (!regEx("password", this.state.password)) {
      this.errMsg("v4");
    } else if (this.state.password !== this.state.confirmPassword) {
      this.errMsg("v5");
    } else {
      axios
        .post(getURL("API") + "/PasswordRecovery/validate", {
          recoveryCode: getPathName(2),
          password: this.state.password,
          confirmPassword: this.state.confirmPassword,
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.msg === "2") window.location.href = "../login";
        })
        .catch((error) => {
          this.errMsg(error.response.data.msg);
        });
    }
  };

  errMsg = (msg) => {
    var emailErrMsg = "";
    var passwordErrMsg = "";
    var confirmPasswordErrMsg = "";
    var validateCodeErrMsg = "";

    if (msg === "v1") {
      emailErrMsg = "Insira seu Email";
    } else if (msg === "v2" || msg === "3") {
      emailErrMsg = "Formato de email incorreto";
    } else if (msg === "v3" || msg === "4") {
      passwordErrMsg = "Sua senha deve ter entre 6 e 150 caracteres";
    } else if (msg === "v4") {
      passwordErrMsg =
        "Sua senha deve conter números, letras e caracteres especiais";
    } else if (msg === "v5" || msg === "4") {
      confirmPasswordErrMsg = "As senhas não conferem";
    } else if (msg === "7") {
      validateCodeErrMsg =
        "Código de recuperação de senha inválido. <a href='./' style=font-size:14px;>Clique aqui</a> para enviar um novo email.";
    }
    ReactDOM.findDOMNode(this.refs.emailErrMsg).innerHTML = emailErrMsg;
    ReactDOM.findDOMNode(this.refs.passwordErrMsg).innerHTML = passwordErrMsg;
    ReactDOM.findDOMNode(this.refs.confirmPasswordErrMsg).innerHTML =
      confirmPasswordErrMsg;
    ReactDOM.findDOMNode(this.refs.validateCodeErrMsg).innerHTML =
      validateCodeErrMsg;
  };

  render() {
    return (
      <>
        <form method="post" className="defaultContainer passwordRecovery">
          <div id="background">
            <div className="align">
              <div className="bgAlign"></div>
              <div className="formAlign">
                <div className="formPasswordRecovery defaultBox">
                  <h4>Recuperação de Senha</h4>
                  <p
                    className={
                      this.state.emailSent || getPathName(2) ? "hidden" : ""
                    }
                  >
                    Informe seu email, enviaremos um código para recuperação
                  </p>
                  <p className={getPathName(2) ? "" : "hidden"}>
                    Crie uma nova senha para acesso
                  </p>
                  <p className={this.state.emailSent ? "" : "hidden"}>
                    Enviamos um email de recuperação de senha para{" "}
                    <strong>{this.state.email}</strong>.
                    <br />
                    <br />
                    Caso não tenha recebido o email,{" "}
                    <a onClick={this.PasswordRecoveryGet}>clique aqui</a> para
                    enviar novamente. Não se esqueça de verificar a caixa de
                    spam
                  </p>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email"
                    onKeyUp={this.handleEmailKeyPress}
                    ref="email"
                    className={
                      this.state.emailSent || getPathName(2) ? "hidden" : ""
                    }
                  />
                  <span
                    className="errMsg"
                    id="emailErrMsg"
                    ref="emailErrMsg"
                  ></span>

                  <input
                    type="password"
                    name="password"
                    id="password"
                    onKeyUp={this.handlePasswordKeyPress}
                    ref="password"
                    maxlength="200"
                    placeholder="Nova senha"
                    className={getPathName(2) ? "" : "hidden"}
                  />
                  <span
                    className="errMsg"
                    id="passwordErrMsg"
                    ref="passwordErrMsg"
                  ></span>
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    onKeyUp={this.handlePasswordKeyPress}
                    ref="confirmPassword"
                    maxlength="200"
                    placeholder="Confirme a nova senha"
                    className={getPathName(2) ? "" : "hidden"}
                  />
                  <span
                    className="errMsg"
                    id="confirmPasswordErrMsg"
                    ref="confirmPasswordErrMsg"
                  ></span>
                  <input
                    type="button"
                    value="Solicitar Recuperação"
                    onClick={this.PasswordRecoveryGet}
                    className={
                      this.state.emailSent || getPathName(2) ? "hidden" : ""
                    }
                  />
                  <input
                    type="button"
                    value="Criar Nova Senha"
                    onClick={this.PasswordRecoveryValidate}
                    className={getPathName(2) ? "" : "hidden"}
                  />
                  <span
                    className="errMsg"
                    id="validateCodeErrMsg"
                    ref="validateCodeErrMsg"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}
