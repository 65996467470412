import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Link, Redirect } from "react-router-dom";
import {
  getDataToken,
  getPathName,
  getSearchKey,
} from "../assets/scripts/Main";

export default class Index extends Component {
  state = {
    search: "",
    redirect: false,
    dataToken: getDataToken(),
    stylePhoto: {
      backgroundImage: getDataToken().profilePicture
        ? "url(" + getDataToken().profilePicture + ")"
        : "url(https://cafemaismais.com.br/assets/img/default_images/" +
          getDataToken().username +
          ".jpg)",
    },
    styleProfileMenu: {
      display: "none",
    },
    styleSignMenu: {
      display: "none",
    },
  };

  handleKeyPress = (event) => {
    this.setState({
      search: ReactDOM.findDOMNode(this.refs.SearchInput).value,
    });

    if (event.key === "Enter") {
      this.redirect(this.state.search);
    }
  };
  handleSearchClick = () => {
    this.setState({
      search: ReactDOM.findDOMNode(this.refs.SearchInput).value,
    });
    this.redirect(this.state.search);
  };

  profileMenuClick = () => {
    if (this.state.styleProfileMenu.display === "none") {
      this.setState({
        styleProfileMenu: { ...this.state.styleProfileMenu, display: "block" },
      });
    } else {
      this.setState({
        styleProfileMenu: { ...this.state.styleProfileMenu, display: "none" },
      });
    }
  };

  signMenuClick = () => {
    if (this.state.styleSignMenu.display === "none") {
      this.setState({
        styleSignMenu: { ...this.state.styleSignMenu, display: "block" },
      });
    } else {
      this.setState({
        styleSignMenu: { ...this.state.styleSignMenu, display: "none" },
      });
    }
  };
  componentDidMount = () => {
    ReactDOM.findDOMNode(this.refs.SearchInput).value =
      getPathName(1) === "busca" ? getSearchKey() : "";
  };

  exitProfile = () => {
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  redirect = (ref) => {
    this.setState({
      redirect: true,
    });
  };

  render() {
    return (
      <>
        {this.state.redirect && (
          <>
            {this.setState({
              redirect: false,
            })}
            <Redirect to={{ pathname: "/busca/?key=" + this.state.search }} />
          </>
        )}
        <header className="headerContainer">
          <div id="headerItems">
            {getPathName(1) ? (
              <Link to="/" id="headerLogo">
                <img src={`/assets/img/logo.png`} />
              </Link>
            ) : (
              <a href="/" id="headerLogo">
                <img src={`/assets/img/logo.png`} />
              </a>
            )}

            <div className="search">
              <input
                type="button"
                id="searchButton"
                value=""
                onClick={this.handleSearchClick}
              />

              <input
                type="text"
                id="search"
                name="search"
                placeholder="Buscar"
                onKeyUp={this.handleKeyPress}
                ref="SearchInput"
                defaultValue={this.state.search}
              />
            </div>

            {this.state.dataToken.username && this.state.dataToken.statusExp ? (
              <div id="profileBox">
                <Link to="/enviar-foto">
                  <input
                    type="button"
                    value="Envie sua foto 📷"
                    id="newArticleButton"
                  />
                </Link>
                <div id="divisor"></div>

                <div
                  id="profileData"
                  onClick={this.profileMenuClick.bind(this)}
                >
                  <div id="userMenuIcon">
                    {/* <Icon icon="menu-hamburguer" width="16" color="#ffffff" /> */}
                  </div>
                  <span className="name">{this.state.dataToken.username}</span>
                  <div className="photo" style={this.state.stylePhoto}></div>
                </div>
              </div>
            ) : (
              <>
                <div id="signMenuIcon" onClick={this.signMenuClick.bind(this)}>
                  {/* <Icon icon="menu-hamburguer" width="16" color="#ffffff" /> */}
                </div>
                <Link to="/cadastro">
                  {/*      <input type="button" value="Criar Acesso" id="signUpButton" /> */}
                </Link>
                <Link to="/login">
                  {/*   <input type="button" value="Login" id="loginButton" /> */}
                </Link>
              </>
            )}

            <div id="profileMenu" style={this.state.styleProfileMenu}>
              <div id="dropdownArrow"></div>
              <div id="informacoesUsuario">
                <div className="nome">
                  {this.state.dataToken.username !== "" &&
                  this.state.dataToken.statusExp
                    ? this.state.dataToken.name
                    : ""}
                </div>
              </div>

              <ul>
                <Link to={"/perfil/" + this.state.dataToken.username}>
                  <li>👤 Perfil</li>
                </Link>
                {/*               <li>
                <Icon icon="notification" width="16" color="#1976D2" />
                Notifficações
              </li> */}
              </ul>
              <ul>
                {/*               <Link to={"/configuracoes"}>
                <li>
                  <Icon icon="config" width="16" color="#E64A19" />
                  Configurações
                </li>
              </Link> */}
                <div onClick={this.exitProfile.bind(this)}>
                  <li>🚪 Sair</li>
                </div>
              </ul>
            </div>
            <div id="signMenu" style={this.state.styleSignMenu}>
              <ul>
                <Link to="/cadastro">
                  <li>Criar Acesso</li>
                </Link>
              </ul>
              <ul>
                <Link to="/login">
                  <li>Login</li>
                </Link>
              </ul>
            </div>
          </div>
        </header>
      </>
    );
  }
}
