import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { verifyJwt } from "./assets/scripts/Main";
import Routes from "./routes";
import SignIn from "./views/SignIn/Index";
import "./assets/styles/Styles";
import "./assets/styles/Main.css";

var stateJwt = verifyJwt([
  "editar-perfil",
  "configuracoes-da-conta",
  "enviar-foto",
]);

ReactDOM.render(
  <BrowserRouter>
    {stateJwt ? <Routes pathName={window.location.pathname} /> : <SignIn />}
  </BrowserRouter>,
  document.getElementById("mainContent")
);

serviceWorker.unregister();
