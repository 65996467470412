import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import CenterContainer from "../../components/CenterContainer";
import { setPageTitle, getPathName } from "../../assets/scripts/Main";
import WaitingLoad from "../../components/WaitingLoad";

export default class Top extends Component {
  render() {
    let photo = this.props.photo;
    let aircraft_id = photo.aircraft_id;
    let registration_code = photo.registration_code;
    let manufacturer = photo.manufacturer;
    let model = photo.model;
    let photo_url = photo.photo_url;

    return (
      <>
        <div id="aircraftPhotoContainer">
          <img
            class="photo"
            src={photo_url || "/assets/img/default_images/aircraft_photo.png"}
          />

          <img src="/assets/img/scroll.gif" className="scroll" />
          <div className="description">
            {manufacturer ? (
              <>
                {(manufacturer ? manufacturer + " " : "") +
                  (model ? model + " " : "") +
                  (manufacturer
                    ? "(" + registration_code + ")"
                    : registration_code)}

                <Link
                  className="link"
                  to={"../../aeronave/" + aircraft_id + "/" + registration_code}
                >
                  Mais sobre {registration_code} ✈️
                </Link>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  }
}
