import React, { Component } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { getURL, getPathName } from "../../assets/scripts/Main";

export default class Content extends Component {
  state = {
    items: [],
  };

  componentDidMount = () => {
    axios
      .post(getURL("API") + "/signUp/validate", {
        verificationCode: getPathName(2),
      })
      .then((response) => response.data)
      .then((data) => {
        this.setState({ items: data });
      })
      .catch((error) => {
        this.errMsg(error.response.data.msg);
      });
  };

  errMsg = (msg) => {
    var errMsg = "";
    if (msg === "9") {
      errMsg =
        "😕 Não conseguimos validar seu email, clique no botão CRIAR ACESSO e refaça seu cadastro.<br><br>Lembrando que o email de validação tem validade de 24h";
    }
    ReactDOM.findDOMNode(this.refs.errMsg).innerHTML = errMsg;
  };

  render() {
    let success = this.state.items.success ? this.state.items.success : null;
    let msg = this.state.items.msg ? this.state.items.msg : null;

    if (success) {
      let dataToken = JSON.parse(atob(msg.split(".")[1]));
      let page = localStorage.getItem("page");

      localStorage.setItem("token", msg);

      if (typeof page !== "undefined" && page !== null) {
        localStorage.removeItem("page");
        window.location.href = page;
      } else {
        window.location.href = "/perfil/" + dataToken.username;
      }
    }

    return (
      <form method="post" className="defaultContainer signUp">
        <div id="background">
          <div className="align">
            <div className="bgAlign"></div>
            <div className="formAlign">
              <div className="formSignUp defaultBox">
                <span className="errMsg" id="errMsg" ref="errMsg"></span>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
