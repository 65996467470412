import React from "react";
import HeaderContainer from "./HeaderContainer";

export default (props) => (
  <>
    {props.header === "noHeader" ? (
      ""
    ) : (
      <>
        <HeaderContainer />
      </>
    )}

    <section
      className={"defaultContainer " + (props.componentclass || "")}
      {...props}
    />
  </>
);
