import React, { Component } from "react";
import DefaultContainer from "../../components/DefaultContainer";
import axios from "axios";
import Left from "./Left";
import Top from "./Top";
import Center from "./Center";
import { Redirect } from "react-router-dom";
import { getURL, getPathName, scrollToTop } from "../../assets/scripts/Main";

export default class Index extends Component {
  state = {
    photo: [],
    user_images: [],
  };

  componentDidMount() {
    scrollToTop();
    axios
      .get(getURL("API") + "/photo/" + getPathName(2))
      .then((response) => response.data)
      .then((data) => {
        this.listUserPhotos(data);
      });
  }

  listUserPhotos = (ac_data) => {
    axios
      .get(getURL("API") + "/photosByUserId/" + ac_data.user_id)
      .then((response) => response.data)
      .then((data) => {
        this.setState({ user_images: data, photo: ac_data });
      });
  };

  render() {
    var url = "";

    this.state.photo.length > 0 &&
      this.state.photo.map((item) => {
        url = item.registration_code;
      });

    return (
      <>
        {url && url !== getPathName(3) && (
          <Redirect to={{ pathname: "/pagina-nao-encontrada" }} />
        )}

        <Top photo={this.state.photo} />
        <DefaultContainer componentclass="aircraftPhotoPage">
          <Left photo={this.state.photo} />
          <Center
            photo={this.state.photo}
            user_images={this.state.user_images}
          />
        </DefaultContainer>
      </>
    );
  }
}
