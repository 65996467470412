import React, { Component } from "react";
import LeftContainer from "../../components/LeftContainer";
import WaitingLoad from "../../components/WaitingLoad";

export default class Left extends Component {
  render() {
    let aircraft = this.props.items;
    let registration_code = aircraft.registration_code;
    let url_thumb = aircraft.url_thumb;

    return (
      <LeftContainer>
        <div className="defaultBox aircraft" id="profile">
          <img
            className="profilePicture"
            src={url_thumb || "../../assets/img/default_images/aircraft.png"}
            alt=""
          />
          <div id="code">
            {registration_code || (
              <WaitingLoad width={"100px"} height={"20px"} />
            )}
            <br />
          </div>
        </div>
      </LeftContainer>
    );
  }
}
