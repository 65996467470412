import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import CenterContainer from "../../components/CenterContainer";
import { getSearchKey, getURL, getPathName } from "../../assets/scripts/Main";

var lastListedId = null;
var lastCalledId = null;
var lastPostPosition = null;
export default class Center extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [],
      aircrafts: [],
      searchWord: getSearchKey(),
    };
  }

  componentDidMount = () => {
    if (getSearchKey().length > 2) {
      this.getPhotos(0);
      this.getAircrafts();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.state.searchWord !== getSearchKey() && getSearchKey().length > 2) {
      lastListedId = null;
      lastCalledId = null;
      lastPostPosition = null;

      this.setState({
        photos: [],
        aircrafts: [],
        searchWord: getSearchKey(),
      });

      window.onscroll = () => {};
      this.getPhotos(lastListedId, getSearchKey());
      this.getAircrafts(getSearchKey());
    }
  };

  getAircrafts = (searchWord) => {
    axios
      .get(
        getURL("API") +
          "/aircraft/search/" +
          (searchWord || this.state.searchWord)
      )
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          aircrafts: data,
        });
      });
  };

  getPhotos = (lastListedId, searchWord) => {
    axios
      .get(
        getURL("API") +
          "/feed/search/" +
          (searchWord || this.state.searchWord) +
          "/" +
          lastListedId
      )
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          photos: this.state.photos.concat(data),
        });
      });
  };

  paginationScroll = (arg) => {
    var post = document.getElementById(`pb${arg}`);
    if (post) {
      lastPostPosition = post.getBoundingClientRect().top;
    }
    if (
      arg &&
      lastPostPosition < window.innerHeight + 200 &&
      lastCalledId !== arg
    ) {
      this.getPhotos(arg);
      lastCalledId = arg || lastCalledId;
      lastListedId = null;
      lastPostPosition = null;
    }
  };

  render() {
    window.onscroll = () => {
      this.paginationScroll(lastListedId);
    };

    return (
      <CenterContainer componentclass={"search noRight"}>
        {this.state.aircrafts.length > 0 && (
          <div className="sessionTitle">Aeronaves</div>
        )}
        {this.state.aircrafts.length > 0 &&
          this.state.aircrafts.map((item) => {
            return (
              <div className={`postBox aircraft`}>
                <Link
                  to={
                    "/aeronave/" +
                    item.aircraft_id +
                    "/" +
                    (item.registration_code || "")
                  }
                >
                  <div className="headerBar">
                    <div className={"title"}>{item.registration_code}</div>
                    <div className="description"></div>
                  </div>

                  <div
                    className="center artigo"
                    style={
                      item.photo_url
                        ? {
                            backgroundImage: `url(${item.photo_url})`,
                          }
                        : {
                            backgroundImage: `url(./assets/img/default_images/aircraft.png)`,
                          }
                    }
                  >
                    {item.desctiption}
                  </div>
                </Link>
                <div className="footerBar">
                  <div
                    className="profilePicture"
                    style={
                      item.last_contributor_profile_picture
                        ? {
                            backgroundImage: `url(${item.last_contributor_profile_picture})`,
                          }
                        : {
                            backgroundImage: `url(./assets/img/default_images/user.png)`,
                          }
                    }
                  ></div>

                  <div className="name">{item.last_contributor_name}</div>
                </div>
              </div>
            );
          })}
        {this.state.photos.length > 0 && (
          <div className="sessionTitle">Fotos</div>
        )}

        {this.state.photos.length > 0 &&
          this.state.photos.map((item) => {
            lastListedId = item.id;
            return (
              <div
                className={`postBox aircraft`}
                id={`pb${item.id}`}
                key={`pb${item.id}`}
              >
                <Link
                  to={"/foto/" + item.id + "/" + (item.registration_code || "")}
                >
                  <div className="headerBar">
                    <div className={"title"}>
                      {item.manufacturer
                        ? item.registration_code +
                          " " +
                          item.manufacturer +
                          (item.model ? "" + item.model : "")
                        : "✈️"}
                    </div>
                    <div className="description"></div>
                  </div>

                  <div
                    className="center artigo"
                    style={
                      item.photo_url
                        ? {
                            backgroundImage: `url(${item.photo_url})`,
                          }
                        : {
                            backgroundImage: `url(./assets/img/default_images/aircraft.png)`,
                          }
                    }
                  >
                    {item.desctiption}
                  </div>
                </Link>
                <div className="footerBar">
                  <div
                    className="profilePicture"
                    style={
                      item.user_profile_picture
                        ? {
                            backgroundImage: `url(${item.user_profile_picture})`,
                          }
                        : {
                            backgroundImage: `url(./assets/img/default_images/user.png)`,
                          }
                    }
                  ></div>

                  <div className="name">{item.name}</div>
                </div>
              </div>
            );
          })}

        {!this.state.aircrafts.length && !this.state.photos.length && (
          <>
            {getSearchKey().length < 3 ? (
              <div className="noResults">Digite pelo menos três caracteres</div>
            ) : (
              <div className="noResults">Nenhum resultado</div>
            )}
          </>
        )}
      </CenterContainer>
    );
  }
}
