import React, { Component } from "react";
import DefaultContainer from "../../components/DefaultContainer";
import Content from "./Content";
import { setPageTitle } from "../../assets/scripts/Main";

export default class Index extends Component {
  render() {
    setPageTitle("404 - Página não encontrada | Aeronaves.Info");
    return (
      <DefaultContainer>
        <Content />
      </DefaultContainer>
    );
  }
}
