import React, { Component } from "react";
import DefaultContainer from "../../components/DefaultContainer";
import Content from "./Content";
import { setPageTitle } from "../../assets/scripts/Main";

export default class Index extends Component {
  render() {
    setPageTitle("Esqueci Minha Senha");
    return (
      <>
        <DefaultContainer>
          <Content />
        </DefaultContainer>
      </>
    );
  }
}
