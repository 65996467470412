import React, { Component } from "react";
import LeftContainer from "../../components/LeftContainer";
import { getDataToken, getPathName } from "../../assets/scripts/Main";
import { Link } from "react-router-dom";
import WaitingLoad from "../../components/WaitingLoad";

export default class Left extends Component {
  render() {
    const logedUser = getDataToken();
    const user = this.props.items;
    const PathName = getPathName(1);
    const perfil = "perfil";
    const editarPerfil = "editar-perfil";
    const enviarFoto = "enviar-foto";

    return (
      <LeftContainer>
        <div className="defaultBox userPage" id="profile">
          <img
            className="profilePicture"
            src={
              (PathName === perfil
                ? user.profilePicture
                : logedUser.profilePicture) ||
              "../../assets/img/default_images/user.png"
            }
            alt=""
          />
          <div id="name">
            {(PathName === perfil ? user.name : logedUser.name) || (
              <WaitingLoad width={"100px"} height={"20px"} />
            )}
          </div>
          <br />

          {PathName !== perfil && (
            <div className="link">
              <Link to={"../" + perfil + "/" + logedUser.username}>
                Ver Perfil 👤
              </Link>
            </div>
          )}
          {PathName !== editarPerfil && user.id === logedUser.id && (
            <div className="link">
              <Link to={"../" + editarPerfil + "/"}>Editar Perfil 🖊️</Link>
            </div>
          )}
          {PathName !== enviarFoto && user.id === logedUser.id && (
            <div className="link">
              <Link to={"../" + enviarFoto + "/"}>Enviar Foto 📷</Link>
            </div>
          )}
        </div>
      </LeftContainer>
    );
  }
}
