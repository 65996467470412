import React, { Component } from "react";
import LeftContainer from "../../components/LeftContainer";
import { Link } from "react-router-dom";
import WaitingLoad from "../../components/WaitingLoad";

export default class Left extends Component {
  render() {
    let photo = this.props.photo;
    let name = photo.name;
    let username = photo.username;
    let user_profile_picture = photo.user_profile_picture;
    return (
      <LeftContainer>
        <div className="defaultBox aircraftPhotoPage" id="profile">
          <img
            className="profilePicture"
            src={
              user_profile_picture || "../../assets/img/default_images/user.png"
            }
            alt=""
          />
          <div id="name">
            {name || <WaitingLoad width={"100px"} height={"20px"} />}
          </div>
          <br />
          <div className="link">
            <Link to={"../../perfil/" + username}>Ver Perfil 👤</Link>
          </div>
        </div>
      </LeftContainer>
    );
  }
}
