import React, { Component } from "react";
import DefaultContainer from "../../components/DefaultContainer";
import Content from "./Content";
import { setPageTitle, getPathName } from "../../assets/scripts/Main";

export default class Index extends Component {
  render() {
    setPageTitle("Login");
    return (
      <>
        {getPathName(1) !== "login" ? (
          <>{window.location.assign("/login")}</>
        ) : (
          <DefaultContainer>
            <Content />
          </DefaultContainer>
        )}
      </>
    );
  }
}
