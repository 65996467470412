import React, { Component } from "react";
import Card from "../../components/Card";
import CenterContainer from "../../components/CenterContainer";
import { setPageTitle } from "../../assets/scripts/Main";
import WaitingLoad from "../../components/WaitingLoad";

export default class Center extends Component {
  state = {
    slideImageUrl: "",
  };

  componentDidMount = () => {
    this.setState({
      slideImageUrl: "",
    });
  };

  handleClickImg = (imgUrl) => {
    this.setState({
      slideImageUrl: imgUrl,
    });
  };

  handleCLoseSlide = () => {
    this.setState({
      slideImageUrl: "",
      slideWasClosed: true,
    });
  };

  render() {
    let photo = this.props.photo;
    let user_images = this.props.user_images;
    let name = photo.name;
    let title =
      (photo.manufacturer ? photo.manufacturer + " " : "") +
      (photo.model ? photo.model + " " : "") +
      (photo.registration_code || "");

    setPageTitle((title ? title + " | " : "") + "Aeronaves.Info");

    return (
      <>
        <CenterContainer componentclass="noRight">
          <Card componentClass="article aircraftPhotoPage">
            <h1>
              {name ? (
                <>Outras fotos de {name}:</>
              ) : (
                <>
                  <WaitingLoad width={"100%"} height={"15px"} />
                  <br />
                  <br />
                  <WaitingLoad width={"50%"} height={"130px"} />
                  <WaitingLoad width={"50%"} height={"130px"} />
                  <br />
                  <br />
                  <WaitingLoad width={"50%"} height={"130px"} />
                  <WaitingLoad width={"50%"} height={"130px"} />
                </>
              )}
            </h1>

            {/* SLIDE  */}
            <div
              id="imgZoomComponent"
              className="photoPage"
              style={{ display: this.state.slideImageUrl ? "unset" : "none" }}
            >
              <div>
                <div
                  onClick={() => this.handleCLoseSlide()}
                  className="closeBtn"
                >
                  {"✕ fechar"}
                </div>
              </div>

              <img class="image" src={this.state.slideImageUrl} />
            </div>
            {/* SLIDE  */}

            <div className="photos">
              {user_images &&
                user_images.map((image) => (
                  <img
                    key={image.id}
                    id={"img_" + image.id}
                    src={image.url}
                    onClick={() => this.handleClickImg(image.url)}
                  />
                ))}
            </div>
          </Card>
        </CenterContainer>
      </>
    );
  }
}
