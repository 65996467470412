import React, { Component } from "react";
import {
  getDataToken,
  getURL,
  getPathName,
  verifyJwt,
  validateImage,
  getBase64,
} from "../../assets/scripts/Main";
import { Avatar, Grid, LinearProgress } from "@mui/material";
import CenterContainer from "../../components/CenterContainer";
import axios from "axios";

export default class Center extends Component {
  constructor(props) {
    super(props);

    this.state = {
      baseUrlPhoto: "",
      userId: null,
      token: localStorage.getItem("token"),
      name: null,
      username: null,
      profilePicture: null,
      profileDescription: null,
      urlTwitter: null,
      urlInstagram: null,
      loading: false,
      enableSaveButton: false,
    };
  }

  componentDidMount = () => {
    this.getProfile();
  };

  handleFileInputChange = async (e) => {
    let { file } = this.state;
    file = e.target.files[0];
    let baseURL = await getBase64(file);
    let validate = await validateImage(baseURL, 2);
    if (!validate.success) {
      this.setState({
        error: true,
        alert: false,
        alertMsg: validate.msg,
        baseUrlPhoto: "",
      });
    } else if (validate.msg) {
      this.setState({
        error: false,
        alert: true,
        alertMsg: validate.msg,
        baseUrlPhoto: baseURL,
      });
    } else {
      this.setState({
        error: false,
        alert: false,
        alertMsg: "",
        baseUrlPhoto: baseURL,
        enableSaveButton: true,
      });
    }
    console.log(validate.msg);
  };

  getProfile = () => {
    let username = getDataToken().username;
    axios
      .get(getURL("API") + "/profile/" + username, {
        headers: { "x-access-token": this.state.token },
      })
      .then((data) => {
        data = data.data;
        this.setState({
          userId: data.id,
          name: data.name,
          username: username,
          profilePicture: data.profilePicture,
          profileDescription: data.profileDescription,
          urlTwitter: null,
          urlInstagram: null,
        });
      });
  };

  editProfile = () => {
    axios
      .request({
        method: "POST",
        url: getURL("API") + "/profile/editProfile/" + this.state.userId,
        headers: { "x-access-token": this.state.token },
        data: {
          name: this.state.name,
          username: this.state.username,
          profileDescription: this.state.profileDescription,
          urlTwitter: this.state.urlTwitter,
          urlInstagram: this.state.urlInstagram,
          baseUrlPhoto: this.state.baseUrlPhoto,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.hasOwnProperty("success")) {
          localStorage.setItem(
            "page",
            getPathName(1) + "/" + getPathName(2) + "/" + getPathName(3)
          );
          window.location.href = "/login";
        } else {
          /*           this.setState({
            visibleAlert: "true",
            msgAlert: "Edição foi realizada com sucesso!",
            severityAlert: "success",
          }); */
        }
      })
      .catch((err) => {
        /*         this.setState({
          visibleAlert: "true",
          msgAlert:
            "Ocorreu um erro na sua solicitação, por favor, tente mais tarde.",
          severityAlert: "danger",
        }); */
      });
  };

  render() {
    return (
      <>
        <CenterContainer>
          <div id="editProfile" className="profileContents">
            <div className="defaultBox">
              <h4>Resumo</h4>
              <textarea
                rows="4"
                cols="50"
                placeholder="Descreva aqui sua bio..."
                value={this.state.profileDescription || ""}
                onChange={(event) => {
                  this.setState({
                    enableSaveButton: true,
                    profileDescription: event.target.value,
                  });
                }}
              ></textarea>
            </div>

            <div className="defaultBox">
              <h4>Dados Pessoais</h4>

              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <label class="profilePictureLabel">Foto de perfil</label>
                </Grid>
                <Grid item xs={5}>
                  <label for="files" className="profilePictureBtn">
                    Selecionar Imagem
                  </label>
                  <input
                    id="files"
                    style={{ display: "none" }}
                    type="file"
                    onChange={this.handleFileInputChange}
                  ></input>
                </Grid>
                <Grid item xs={4}>
                  <Avatar
                    alt="Remy Sharp"
                    src={this.state.baseUrlPhoto || this.state.profilePicture}
                  />
                </Grid>

                <Grid item xs={2}>
                  <label>Nome</label>
                </Grid>
                <Grid item xs={10}>
                  <input
                    type="text"
                    name="nome"
                    id="nome"
                    placeholder="John Malone"
                    value={this.state.name || ""}
                    onChange={(event) => {
                      this.setState({
                        enableSaveButton: true,
                        name: event.target.value,
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <label>Usuário</label>
                </Grid>
                <Grid item xs={10}>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="username"
                    value={this.state.username || ""}
                    onChange={(event) => {
                      this.setState({
                        enableSaveButton: true,
                        username: event.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            <div className="defaultBox last">
              <h4>Redes</h4>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <label>Instagram</label>
                </Grid>
                <Grid item xs={10}>
                  <input
                    type="text"
                    name="instagram"
                    id="instagram"
                    value={this.state.urlInstagram || ""}
                    placeholder="https://www.instagram.com/usuario"
                    onChange={(event) => {
                      this.setState({
                        enableSaveButton: true,
                        urlInstagram: event.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <label>𝕏 (Twitter)</label>
                </Grid>
                <Grid item xs={10}>
                  <input
                    type="text"
                    name="twitter"
                    id="twitter"
                    value={this.state.urlTwitter || ""}
                    placeholder="https://x.com/usuario"
                    onChange={(event) => {
                      this.setState({
                        enableSaveButton: true,
                        urlTwitter: event.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress
                    sx={{
                      visibility: this.state.loading ? "visible" : "hidden",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="buttonAlign">
                    <input
                      type="button"
                      className={
                        "editProfileButton " +
                        (this.state.enableSaveButton ? "" : "disabled")
                      }
                      value="Salvar"
                      onClick={this.editProfile.bind(this)}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </CenterContainer>
      </>
    );
  }
}
