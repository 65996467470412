import React, { Component } from "react";
import DefaultContainer from "../../components/DefaultContainer";
import CenterContainer from "../../components/CenterContainer";
import Left from "./Left";
import Right from "./Right";
import Center from "./Center";
import { setPageTitle } from "../../assets/scripts/Main";

export default class Index extends Component {
  render() {
    setPageTitle("Aeronaves.Info");

    return (
      <DefaultContainer>
        <Left />
        <Center className={"feed"} />
        {/*      <Right /> */}
      </DefaultContainer>
    );
  }
}
