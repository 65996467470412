import React from "react";
export default props => (
  <>
    <section className="leftContainer">
      <div className="content">
        <div {...props} />
      </div>
    </section>
  </>
);
